import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonBack, InputField, Layout } from "../../../components"
import { RootState } from "../../app/mainReducer";
import { AcaoHeader, BotaoFinalizarEntrega, Images, ModalObservacoes, Temporizador } from "../components"
import { useEffect, useState } from 'react';
import { Flex, Radio, Stack } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import RadioField from "../../../components/RadioField";
import { entregaActions } from "../reducer";
import { Carga } from "../../carga/types";

const RegistrarClienteAusente = () => {
  const gestao_entrega_temporario = useSelector((state: RootState) => state.entrega.gestao_entrega_temporario);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ukey, tipo } = useParams();
  const e_transbordo = tipo === 'transbordo';

  useEffect(() => {
    if (!gestao_entrega_temporario) {
      if (e_transbordo) {
        navigate(`/`)
        return;
      }

      navigate(`/cargas/${ukey}`)
    }
  }, [navigate, gestao_entrega_temporario]);

  const [openModalObservacoes, setOpenModalObservacoes] = useState(false);
  const [openedModalObservacoes, setOpenedModalObservacoes] = useState(false);

  const carga = useSelector((state: RootState) =>
    (state.carga.carga || state.carga.cargas?.find((i) => i.j14_ukey === ukey))
  );

  return (
    <Layout showFooter={true} isLoading={false} title={
      <>
        <ButtonBack />
        <Temporizador />
      </>
    }>
      <AcaoHeader label="Registrar Cliente Ausente" />

      <Flex px={4} width="full" direction="column">
        {gestao_entrega_temporario && <Formik
          enableReinitialize
          initialValues={gestao_entrega_temporario}
          onSubmit={(val, { setErrors }) => {
            if (!val.url_imagem1) {
              setErrors({ url_imagem1: 'Tire a foto do canhoto!' })
              return;
            }

            if (!openedModalObservacoes) {
              setOpenModalObservacoes(true);
              return;
            }

            console.log('registrar cliente ausente', val);
            dispatch(entregaActions.registraNovoGestaoEntrega({
              gestao: {
                ...val,
                motivo_devolucao: val.e_tentativa_entrega == 0 ? '4' : '1', // Se for finalizar, marcar como "Cliente ausente" 
              }, carga: carga as Carga
            }))
          }}
        >
          {({ values, handleSubmit }) => (
            <Form>
              <RadioField name="e_tentativa_entrega">
                <Stack mx="auto" direction="column" bg="white" p={4} borderRadius={5}>
                  <Radio size="lg" value={0}>Não vou reentregar, finalizar.</Radio>
                  <Radio size="lg" value={1}>Vou reentregar depois.</Radio>
                </Stack>
              </RadioField>

              <Images />

              <ModalObservacoes onSubmited={handleSubmit} onOpenModal={openModalObservacoes} onOpenedModal={() => setOpenedModalObservacoes(true)} />

              <BotaoFinalizarEntrega />
            </Form>
          )}
        </Formik>}
      </Flex>
    </Layout >
  )
}

export default RegistrarClienteAusente;