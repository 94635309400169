import { Badge, Box, Button, CircularProgress, Container, Flex, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import { ReactNode } from 'react';
import { FiRefreshCw, FiLogOut, FiHome, FiSend } from 'react-icons/fi'
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../modules/app/mainReducer";
import { FiTruck } from 'react-icons/fi';
import { loginActions } from "../modules/login/reducer";
import { useSendPendentes } from "../hooks/useSendPendentes";
import { useGeolocalizacao } from "../hooks/useGeolocalizacao";
import { useError } from "../hooks/useError";
import { useSuccess } from "../hooks/useSuccess";
import { useGeolocalizacaoAtual } from "../hooks/useGeolocalizacaoAtual";
import { cargaActions } from "../modules/carga/reducer";
import { useNavigate } from "react-router-dom";
import ButtonBackFooter from "./ButtonBackFooter";
import { entregaActions } from "../modules/entrega/reducer";

interface IProps {
  children: ReactNode;
  title?: any;
  onSearch?: () => void;
  isLoading?: boolean;
  maxW?: string;
  canLogout?: boolean;
  maxWTop?: string;
  showFooter?: boolean;
  backTo?: string;
}

const Layout = ({ children, title = '', onSearch, isLoading = false, maxW = "600px", maxWTop = "", canLogout = false, showFooter = false, backTo = '' }: IProps) => {
  const networkOnline = useSelector((state: RootState) => state.login.networkOnline || false);
  useSendPendentes();
  useGeolocalizacao();
  useError();
  useSuccess();
  useGeolocalizacaoAtual();
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(loginActions.logout());
    dispatch(cargaActions.cargasRequestSuccess([]));
  }

  const nagivate = useNavigate();
  const usuarioLogado = useSelector((state: RootState) => state.login?.parceiro);
  const eTransbordo = useSelector((state: RootState) => state.login?.eTransbordo);

  const goToHome = () => {
    if (eTransbordo) {
      nagivate('/transbordos');
      return;
    }
    nagivate('/');
  }

  const gestao_entrega_pendentes = useSelector((state: RootState) => state.entrega.gestao_entrega_pendentes);

  const onSendEntregas = () => {
    if (gestao_entrega_pendentes) {
      for (const gestao of gestao_entrega_pendentes) {
        if (gestao) {
          dispatch(entregaActions.requestEnviaGestaoEntrega(gestao));
        }
      }
    }
  }

  return (
    <Flex bgColor="gray.200" direction="column" w="full" minH="100vh" position="relative">
      <Flex direction="column" bgColor="blue.400" position="fixed" zIndex={10} width="full">
        <Container maxW={maxWTop ? maxWTop : maxW} p={2}>
          <Flex justifyContent="space-between">
            <Popover colorScheme="blue">
              <PopoverTrigger>
                <Flex my="auto">
                  <IconButton colorScheme="blue" aria-label="Menú" icon={<FiTruck color="white" size="2rem" />} />
                </Flex>
              </PopoverTrigger>
              <PopoverContent boxSize="xxs">
                <Flex>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>
                    {usuarioLogado && <Button mb={2} size="sm" leftIcon={<FiHome />} colorScheme="blue" width="full" onClick={goToHome}>SUAS CARGAS</Button>} <br />
                    {canLogout && <Button size="sm" leftIcon={<FiLogOut />} colorScheme="blackAlpha" width="full" onClick={logout}>SAIR</Button>}
                  </PopoverBody>
                </Flex>
              </PopoverContent>
            </Popover>
            {title}
            {networkOnline && <Badge my="auto" p={2} borderRadius={25} colorScheme='green'>Internet Online <small>v2.3</small></Badge>}
            {!networkOnline && <Badge my="auto" p={2} borderRadius={25} colorScheme='red'>Internet Offline <small>v2.3</small></Badge>}

            <Flex>
              {isLoading && <CircularProgress size="2rem" isIndeterminate />}
              {typeof onSearch === 'function' && <IconButton ml={2} aria-label="Atualizar dados" colorScheme="blue" icon={<FiRefreshCw />} onClick={onSearch} />}
              <Box position={"relative"}>
                <IconButton ml={2} aria-label="Enviar Dados" colorScheme="blue" icon={<FiSend />} onClick={onSendEntregas} />
                <Box position={"absolute"} top={"-5px"} right={"-5px"} bg={"white"} px={1} borderRadius={"full"}>
                  <small>{(gestao_entrega_pendentes ?? []).length}</small>
                </Box>
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Flex>

      <Container as="main" p={0} mt={16} mb={16} maxW={maxW}>
        {children}
      </Container>
      {showFooter && <Box
        as="footer"
        position="fixed"
        bottom="0"
        width="100%"
        bg="gray.200"
      >
        <ButtonBackFooter to={backTo} />
      </Box>}
    </Flex >

  )
}

export default Layout;