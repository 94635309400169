import { Box, Flex, Heading, Text, useMediaQuery } from "@chakra-ui/react";
import { formatDate } from "../../../utils/formatDate";
import { Carga as CargaType } from "../types";
import { FiArrowLeft } from "react-icons/fi";

interface IProps {
  carga: CargaType;
  onClick: () => void;
}

const Carga = ({ carga, onClick }: IProps) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')


  return (
    <Box onClick={onClick} _hover={{ backgroundColor: '#f7f7f7' }} position={"relative"} cursor="pointer" shadow="md" bg="white" mb={1}>
      <Flex justifyContent="space-between">
        <Box p={3}>
          <Heading size="md">Carga: {carga.numero} - {formatDate(carga.data_emissao)} </Heading>
          <Text>Total entregas: {carga.qt_pedidos} | Em aberto: {carga.itens.length}</Text>
        </Box>
        {isMobile && <Flex position={"absolute"} top={6} right={2} fontWeight={700} fontSize={isMobile ? "16px" :"22px"} my={"auto"} alignItems={"center"}>
         <FiArrowLeft />&nbsp;Clique aqui
        </Flex>}
      </Flex>
    </Box>
  )
}


export default Carga;