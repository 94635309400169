import { useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../modules/app/mainReducer";
import { entregaActions } from "../modules/entrega/reducer";
import { useEffect } from 'react';

export function useError() {
  const error = useSelector((state: RootState) => state.entrega.error);
  const toast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      toast({
        title: 'Erro!',
        description: error,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      dispatch(entregaActions.setError(''));
    }
  }, [dispatch, error]);
}
