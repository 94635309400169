import {
  Box,
  Flex,
  Text,
} from '@chakra-ui/react'
import { FiInfo } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/mainReducer';

const InfoSemCarga = () => {
  const usuarioLogado = useSelector((state: RootState) => state.login?.parceiro);

  return (
    <Box px={2}>
      <Box bg="green.500" p={3} borderRadius={5}>
        <Flex align="center">
          <FiInfo fontSize="3em" color="white" />
          <Flex ml={5} direction="column">
            <Text fontSize="lg" fontWeight="bold" color="white">
              Olá{usuarioLogado ? ` ${usuarioLogado?.razao_social}` : ` `}, nenhuma carga foi encontrada.
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}

export default InfoSemCarga;
