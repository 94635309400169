import { Button, Flex, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Radio, Stack, useDisclosure } from "@chakra-ui/react"
import { FiSave } from "react-icons/fi"
import { InputField } from "../../../components"
import { useEffect } from 'react';
import RadioField from "../../../components/RadioField";
import InputImage from "../../../components/InputImage";
import { GestaoEntrega } from "../types";

interface IProps {
  onOpenModal: boolean;
  onOpenedModal: () => void;
  onSubmited: () => void;
  isLoading?: boolean;
  notaPallet?: boolean;
  values?: GestaoEntrega | null;
}

const ModalObservacoes = ({ onOpenModal, onOpenedModal, onSubmited, isLoading = false, notaPallet = false, values = null }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (onOpenModal) {
      onOpen();
      onOpenedModal();
    }
  }, [onOpenModal]);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <FormLabel mt={4}>Como foi esta entrega? Se houve algum problema e queira nos relatar, escreva abaixo!</FormLabel>
          <InputField
            rows={3}
            textarea={true}
            type="textarea"
            label=""
            placeholder="Escreva aqui... (Opcional)"
            name="observacoes"
          />

          {notaPallet &&
            <>
              <FormLabel mb={0} mt={2}>Pallet está retornando?</FormLabel>
              <RadioField name="status_nota_pallet_gestao_entrega" >
                <Stack mx="auto" direction="row" bg="white" p={4} borderRadius={5}>
                  <Radio size="lg" value={2}>Sim</Radio>
                  <Radio size="lg" value={1}>Não</Radio>
                  <Radio size="lg" value={3}>Emitiu vale pallet</Radio>
                </Stack>
              </RadioField>
            </>
          }

          {values && !notaPallet && <>
            <FormLabel mb={0} mt={2}>Cliente devolveu alguma mercadoria ou fez alguma nota de devolução?</FormLabel>
            <RadioField name="teve_devolucao_mercadoria" >
              <Stack mx="auto" direction="row" bg="white" p={4} borderRadius={5}>
                <Radio size="lg" value={1}>Sim</Radio>
                <Radio size="lg" value={0}>Não</Radio>
              </Stack>
            </RadioField>
            {values.teve_devolucao_mercadoria == 1 && <Flex pb={4} width="full" justifyContent="center" wrap="wrap">
              <InputImage label="Adicionar Foto da Nota Fiscal" name="url_imagem2" />
            </Flex>}
          </>}
          <Button isLoading={isLoading} mt={4} mb={4} py={6} width="full" colorScheme="green" onClick={onSubmited}>Concluir &nbsp; <FiSave fontSize={18} /> </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalObservacoes;