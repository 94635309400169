import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  Skeleton,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Error, Layout } from "../../../components";
import { useIsAuth } from "../../../hooks/useIsAuth";
import { RootState } from "../../app/mainReducer";
import { useEffect, useState } from "react";
import { cargaActions } from "../reducer";
import { Carga, CargaTransbordo, InfoSemCarga } from "../components";
import { Link, useNavigate } from "react-router-dom";
import { Carga as CargaType } from "../types";
import { imagensRejeitadasActions } from "../../imagens-rejeitadas/reducer";

const CargaList = () => {
  useIsAuth();
  const isLoading = useSelector((state: RootState) => state.carga.isLoading);
  const cargas = useSelector((state: RootState) => state.carga.cargas);
  const imagensRejeitadas = useSelector(
    (state: RootState) => state.imagensRejeitadas.imagensRejeitadas ?? []
  );
  const error = useSelector((state: RootState) => state.carga.error || "");
  const networkOnline = useSelector(
    (state: RootState) => state.login.networkOnline || false
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!cargas || cargas.length == 0) {
      dispatch(cargaActions.cargasRequest());
      dispatch(imagensRejeitadasActions.imagensRejeitadasRequest());
    }
  }, [dispatch, cargaActions]);

  const eTransbordo = useSelector((state: RootState) => state.login?.eTransbordo);

  const nagivate = useNavigate();

  useEffect(() => {
    if (eTransbordo) {
        nagivate('/transbordos');
    }
  }, []);

  const search = () => {
    if (networkOnline) {
      dispatch(cargaActions.cargasRequest());
      dispatch(imagensRejeitadasActions.imagensRejeitadasRequest());
    } else {
      alert("Você está sem internet, não foi possível buscar os dados.");
    }
  };

  const [filtro, setFiltro] = useState("");

  const getCargasFiltradas = (): CargaType[] => {
    if (!cargas) return [];

    if (!filtro.trim()) return cargas;

    return cargas.filter(
      (i) =>
        i.itens.filter((j) => j.nota_numero.includes(filtro.trim())).length > 0
    );
  };

  const quantidadeFotosRejeitadas = imagensRejeitadas.reduce(
    (soma, registroAtual) => {
      let somaAtual = soma;
      if (registroAtual.gestao_imagem1_rejeitada == 1) {
        somaAtual++;
      }
      if (registroAtual.gestao_imagem2_rejeitada == 1) {
        somaAtual++;
      }
      return somaAtual;
    },
    0
  );

  return (
    <Layout canLogout={true} isLoading={isLoading} onSearch={search}>
      {quantidadeFotosRejeitadas > 0 && (
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          width={"full"}
          textAlign={"center"}
          mt={2}
        >
          Você possui {quantidadeFotosRejeitadas} fotos rejeitadas pela empresa.
          <Link to={"/imagens-rejeitadas"}>
            <Button colorScheme="blue">Clique aqui para reenviá-las</Button>
          </Link>
        </Flex>
      )}
      <Center mt={4}>
        <Heading>Suas cargas em aberto</Heading>
      </Center>
      <Center mb={2}>
        <Heading size="sm">Selecione uma carga (clique)</Heading>
      </Center>
      <Flex mb={4} width={"full"} wrap="wrap">
        <Input
          borderRadius={0}
          bg="white"
          placeholder="Busque aqui pelo número da nota"
          onChange={(evt) => setFiltro(evt.target.value)}
        />
      </Flex>
      <Error error={error} />
      {isLoading &&
        [1, 2, 3, 4, 5, 6].map((i) => (
          <Skeleton height="90px" mb={1} key={i} />
        ))}
      {!isLoading &&
        getCargasFiltradas().map((carga) => (
          <div key={carga.j14_ukey}>
            {!carga.e_transbordo && (
              <Carga
                onClick={() => navigate(`/cargas/${carga.j14_ukey}`)}
                carga={carga}
              />
            )}
            {carga.e_transbordo && <CargaTransbordo carga={carga} />}
          </div>
        ))}
      {!isLoading && getCargasFiltradas().length === 0 && <InfoSemCarga />}
    </Layout>
  );
};

export default CargaList;
