import { Box, Button, Center, Heading, Text } from "@chakra-ui/react";
import { APIURL, SAERPTRANSAPIURL } from "../../../utils/apiCall";

const OpcoesDownloadCarga = ({
  j14_ukey,
  j38_ukey,
}: {
  j14_ukey: string;
  j38_ukey: string;
}) => {
  const getMensagemIndisponibilidade = () => {
    if (!j14_ukey && !j38_ukey) {
      return "(Ainda não disponível)";
    }

    return "";
  };

  const downloadXmlCtes = async () => {
    window.open(
      SAERPTRANSAPIURL +
        "/gestao-de-entrega/v2/transbordos/xmls-ctes/" +
        j38_ukey
    );
  };

  const downloadXmlNfes = async () => {
    window.open(
      APIURL + "/gestao-de-entrega/v2/transbordos/xmls-nfes/" + j38_ukey
    );
  };

  const downloadPdfCtes = async () => {
    window.open(
      SAERPTRANSAPIURL +
        "/gestao-de-entrega/v2/transbordos/pdfs-ctes/" +
        j38_ukey
    );
  };

  const downloadPdfNfes = async () => {
    window.open(
      APIURL + "/gestao-de-entrega/v2/transbordos/pdfs-nfes/" + j38_ukey
    );
  };

  const downloadPdfRomaneioCargaCidade = async () => {
    window.open(
      APIURL + "/logistica/relatorio/entregas-da-carga-por-cidade/" + j14_ukey
    );
  };

  const downloadPdfRomaneioPedidoNota = async () => {
    window.open(
      APIURL + "/logistica/relatorio/pedidos-e-notas/" + j14_ukey
    );
  };

  const downloadPdfCanhotoDasNotas = async () => {
    window.open(
      APIURL + "/logistica/relatorio/canhoto-das-notas/" + j14_ukey
    );
  };

  return (
    <Box p={2} bg="white" rounded="md">
      <Center mt={1} mb={2}>
        <Heading size="md" pt="2">
          INFORMAÇÕES
        </Heading>
      </Center>

      <Center mt={1} mb={2}>
        <Text size="sm" pb="2">
          Clique para fazer download
        </Text>
      </Center>

      <Button onClick={downloadXmlNfes} width="full" mb={2}>
        XML's Notas Fiscais {getMensagemIndisponibilidade()}
      </Button>
      <Button onClick={downloadPdfNfes} width="full" mb={2}>
        PDF's Notas Fiscais {getMensagemIndisponibilidade()}
      </Button>
      <Button onClick={downloadXmlCtes} width="full" mb={2}>
        XML's CTE's {getMensagemIndisponibilidade()}
      </Button>
      <Button onClick={downloadPdfCtes} width="full" mb={2}>
        PDF's CTE's {getMensagemIndisponibilidade()}
      </Button>
      <Button onClick={downloadPdfRomaneioCargaCidade} width="full" mb={2}>
        PDF Romaneio Entrega/Cidade
      </Button>
      <Button onClick={downloadPdfRomaneioPedidoNota} width="full" mb={2}>
        PDF Romaneio Pedido/Nota
      </Button>
      <Button onClick={downloadPdfCanhotoDasNotas} width="full" mb={2}>
        PDF Canhoto Das Notas
      </Button>
      {/* <Button width="full" mb={2}>Romaneio Nota Fiscais</Button>
      <Button width="full" mb={2}>Romaneio Expedição</Button>
      <Button width="full">Canhoteira</Button> */}
    </Box>
  );
};

export default OpcoesDownloadCarga;
