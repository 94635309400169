import { Box, Button, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, SimpleGrid, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { FiArrowDownLeft, FiArrowRight, FiEye } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { InputField, InputVerImagem, SelectField } from "../../../components";
import RadioField from "../../../components/RadioField";
import { formatCep } from "../../../utils/formatCep";
import { formatCnpj } from "../../../utils/formatCnpj";
import { formatDateTime } from "../../../utils/formatDate";
import { RootState } from "../../app/mainReducer";
import { DadosComplementaresButton } from "../../carga/components";
import CelularClienteButton from "../../carga/components/CelularClienteButton";
import { BotaoFinalizarEntrega, Images, ModalObservacoes } from "../../entrega/components";
import { novoGestaoDeEntrega } from "../../entrega/data";
import { getMotivosNaoEntrega } from "../../entrega/utils";
import { transbordoActions } from "../reducer";
import { TransbordoItem as TransbordoItemType } from "../types";

interface IProps {
  item: TransbordoItemType;
}

const TransbordoItem = ({ item }: IProps) => {
  const itemSuccess = useSelector((state: RootState) => state.transbordo.success);
  const isLoadingItem = useSelector((state: RootState) => state.transbordo.isLoadingItem);

  const getVendedor = () => {
    return {
      vendedor_codigo: item.vendedor_codigo,
      vendedor_celular: item.vendedor_celular,
      vendedor_nome: item.vendedor_nome,
    }
  }

  const { isOpen: isOpenRealizarEntrega, onOpen: onOpenRealizarEntrega, onClose: onCloseRealizarEntrega } = useDisclosure()
  const { isOpen: isOpenVerEntrega, onOpen: onOpenVerEntrega, onClose: onCloseVerEntrega } = useDisclosure()
  const { isOpen: isOpenRealizarDevolucao, onOpen: onOpenRealizarDevolucao, onClose: onCloseRealizarDevolucao } = useDisclosure()

  useEffect(() => {
    if (!itemSuccess) {
      onCloseRealizarEntrega();
      onCloseRealizarDevolucao();
    }
  }, [itemSuccess]);

  const gestao_entrega_temporario = novoGestaoDeEntrega();

  const dispatch = useDispatch();

  const [openModalObservacoes, setOpenModalObservacoes] = useState(false);
  const [openedModalObservacoes, setOpenedModalObservacoes] = useState(false);

  return (
    <>
      <Modal onClose={onCloseRealizarEntrega} isOpen={isOpenRealizarEntrega} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Entregar Nota Fiscal {item.nota_numero} <Button onClick={onCloseRealizarEntrega} colorScheme="blackAlpha">Voltar</Button></ModalHeader>
          <ModalCloseButton />
          <ModalBody p={4}>
            <Formik
              enableReinitialize
              initialValues={gestao_entrega_temporario}
              onSubmit={(val, { setErrors }) => {
                if (!val.url_imagem1) {
                  setErrors({ url_imagem1: 'Tire a foto do canhoto!' })
                  return;
                }

                if (!val.data_entrega) {
                  setErrors({ data_entrega: 'Campo obrigatório.' })
                  return;
                }

                if (!openedModalObservacoes) {
                  setOpenModalObservacoes(true);
                  return;
                }

                dispatch(transbordoActions.salvarGestaoEntregaRequest({
                  ...val,
                  j81_par: 'J10',
                  j81_ukeyp: item.j10_ukey,
                  latitude: 0,
                  longitude: 0,
                }));
              }}
            >
              {({ values, handleSubmit }) => (
                <Form>
                  <InputField
                    label="Data/Hora da Entrega"
                    name="data_entrega"
                    type="datetime-local"
                  />

                  <Images />

                  <ModalObservacoes isLoading={isLoadingItem} onSubmited={handleSubmit} onOpenModal={openModalObservacoes} onOpenedModal={() => setOpenedModalObservacoes(true)} />

                  <BotaoFinalizarEntrega isLoading={isLoadingItem} />
                </Form>
              )}
            </Formik>

          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal onClose={onCloseRealizarDevolucao} isOpen={isOpenRealizarDevolucao} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Devolução Nota Fiscal {item.nota_numero} <Button onClick={onCloseRealizarDevolucao} colorScheme="blackAlpha">Voltar</Button></ModalHeader>
          <ModalCloseButton />
          <ModalBody p={4}>
            <Formik
              enableReinitialize
              initialValues={gestao_entrega_temporario}
              onSubmit={(val, { setErrors }) => {
                if (!val.url_imagem1) {
                  setErrors({ url_imagem1: 'Tire a foto do canhoto!' })
                  return;
                }

                if (!val.data_entrega) {
                  setErrors({ data_entrega: 'Campo obrigatório.' })
                  return;
                }

                if (parseInt(val.motivo_devolucao) <= 1) {
                  setErrors({ motivo_devolucao: 'Campo obrigatório' })
                  return;
                }

                if (!openedModalObservacoes) {
                  setOpenModalObservacoes(true);
                  return;
                }

                dispatch(transbordoActions.salvarGestaoEntregaRequest({
                  ...val,
                  j81_par: 'J10',
                  j81_ukeyp: item.j10_ukey,
                  latitude: 0,
                  longitude: 0,
                }));
              }}
            >
              {({ values, handleSubmit }) => (
                <Form>
                  <InputField
                    label="Data/Hora da Devolução"
                    name="data_entrega"
                    type="datetime-local"
                  />

                  <SelectField
                    label="Motivo da Devolução"
                    name="motivo_devolucao"
                  >
                    {getMotivosNaoEntrega().map(motivo => (
                      <option key={`${motivo.value}`} value={motivo.value}>{motivo.name}</option>
                    ))}
                  </SelectField>

                  {values.motivo_devolucao == '4' && <RadioField mt={2} name="e_tentativa_entrega">
                    <Stack mx="auto" direction="column" bg="white" p={4} borderRadius={5}>
                      <Radio size="lg" value={0}>Não vou reentregar, finalizar.</Radio>
                      <Radio size="lg" value={1}>Vou reentregar depois.</Radio>
                    </Stack>
                  </RadioField>}

                  <Images />

                  <ModalObservacoes isLoading={isLoadingItem} onSubmited={handleSubmit} onOpenModal={openModalObservacoes} onOpenedModal={() => setOpenedModalObservacoes(true)} />

                  <BotaoFinalizarEntrega isLoading={isLoadingItem} />
                </Form>
              )}
            </Formik>

          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal onClose={onCloseVerEntrega} isOpen={isOpenVerEntrega} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detalhes da Entrega - Nota Fiscal {item.nota_numero}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={4}>
            <Heading size="sm">Data Entrega: {formatDateTime(item.gestao_data_entrega)}</Heading>
            <Heading mb={4} size="sm">Observações: {item.gestao_observacoes ? item.gestao_observacoes : 'Nenhuma'}</Heading>

            {item.gestao_url_imagem1 && <InputVerImagem label="Ver canhoto" url={item.gestao_url_imagem1} />}
            {item.gestao_url_imagem2 && <InputVerImagem label="Ver imagem 2" url={item.gestao_url_imagem2} />}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onCloseVerEntrega} colorScheme="blackAlpha">Voltar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box _hover={item.j81_ukey ? {} : { backgroundColor: '#f7f7f7' }} shadow="md" bg={item.j81_ukey ? 'green.200' : 'white'} mb={1} mx={2}>
        <Flex justifyContent="space-between">
          <Box width="full" p={3}>
            <Heading size="md">
              Nota Fiscal: {item.nota_numero} &nbsp;
              <DadosComplementaresButton vendedor={getVendedor()} dados_complementares={item} pessoa={item.cliente_razao_social} />
              {item.j81_ukey && <Button onClick={onOpenVerEntrega} size="sm" ml={1} colorScheme="green"><FiEye fontSize="1.3rem" /> <Text ml={1}>VER ENTREGA</Text></Button>}
              {(!item.j81_ukey || (!item.j81_ukey && item.e_reentrega == '1')) && <Button onClick={onOpenRealizarEntrega} size="sm" ml={1} colorScheme="green"><Text mr={1}>REALIZAR {item.e_reentrega == '1' ? 'RE-ENTREGA' : 'ENTREGA'}</Text> <FiArrowRight fontSize="1.3rem" /></Button>}
              {(!item.j81_ukey || (!item.j81_ukey && item.e_reentrega == '1')) && <Button onClick={onOpenRealizarDevolucao} size="sm" ml={1} colorScheme="yellow"><Text mr={1}>REALIZAR OCORRÊNCIA</Text> <FiArrowDownLeft fontSize="1.3rem" /></Button>}
            </Heading>
            <SimpleGrid columns={2} fontSize="0.9rem">
              <Box pr={1}>
                <small>Cidade: {item.cliente_cidade} ({formatCep(item.cliente_cep)})</small> <br />
                <small>Endereço: {item.cliente_endereco}, n° {item.cliente_numero} - {item.cliente_bairro}</small> <br />
                {item?.cliente_celular &&
                  <CelularClienteButton celular={item?.cliente_celular} />
                }
              </Box>
              <Box>
                <small>CNPJ: {formatCnpj(item.cliente_cnpj)}</small> <br />
                <small>Fantasia: {item.cliente_fantasia}</small> <br />
                <small>Razão Social: {item.cliente_razao_social}</small> <br />
              </Box>
            </SimpleGrid>
          </Box>
        </Flex >
      </Box>
    </>
  )
}

export default TransbordoItem;