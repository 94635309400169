import { Center, Heading, Skeleton, Input, FormLabel, Flex } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonBack, Error, Layout } from "../../../components";
import { RootState } from "../../app/mainReducer";
import { useEffect, useState } from 'react';
import { cargaActions } from "../reducer";
import { CargaFinalizada, CargaItem } from "../components";
import { useNavigate, useParams } from "react-router-dom";

const CargaItemList = () => {
  const isLoading = useSelector((state: RootState) => state.carga.isLoading);
  const carga = useSelector((state: RootState) => state.carga.carga);
  const cargas = useSelector((state: RootState) => state.carga.cargas);
  const error = useSelector((state: RootState) => state.carga.error || '');
  const networkOnline = useSelector((state: RootState) => state.login.networkOnline || false);

  const { ukey } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!ukey) return;

    const cargaEncontrada = cargas?.find((i) => i.j14_ukey == ukey);

    if (cargaEncontrada) {
      dispatch(cargaActions.cargaRequestSuccess(cargaEncontrada))
      return;
    }

    if (carga && carga.j14_ukey == ukey) {
      return;
    }

    if (networkOnline) {
      dispatch(cargaActions.cargaRequest({ ukey }));
    } else {
      dispatch(cargaActions.cargasError('Não é possível buscar as entregas, você está sem internet.'));
    }
  }, [dispatch, cargaActions, cargas, ukey])

  const search = () => {
    if (networkOnline) {
      if (!ukey) return;

      dispatch(cargaActions.cargaRequest({ ukey }));
    }
  }
  const [filtro, setFiltro] = useState("");


  const getItens = () => {
    if (!carga?.itens) return [];

    if (!filtro) return carga?.itens;

    return carga.itens.filter((i) => {
      return i.cliente_fantasia.toLowerCase().includes(filtro.toLowerCase()) ||
        i.cliente_razao_social.toLowerCase().includes(filtro.toLowerCase()) ||
        i.nota_numero.toLowerCase().includes(filtro.toLowerCase())
    });
  }

  return (
    <Layout showFooter={true} backTo="/cargas" isLoading={isLoading} onSearch={search} title={<ButtonBack to="/cargas" />}>
      <>
        <Center mt={1} mb={2}>
          <Heading>Notas da carga {carga?.numero}</Heading>
        </Center>

        <Error error={error} />
        {(getItens().length > 0 || (getItens().length == 0 && filtro)) &&
          <Flex width="full" wrap="wrap">
            <FormLabel mx={2}>Filtrar pelo número da Nota ou Cliente</FormLabel>
            <Input mx={2} mb={4} bg="white" placeholder="Digite o número da nota ou nome do cliente" value={filtro} onChange={(e) => setFiltro(e.target.value)} />
          </Flex>
        }
        {
          isLoading && [1, 2, 3, 4, 5, 6].map((i) => (
            <Skeleton height='90px' mb={1} key={i} />
          ))
        }
        {
          !isLoading && getItens().map((carga_item) => (
            <CargaItem onClick={() => navigate(`/entrega/nota/${ukey}/${carga_item.j10_ukey}/opcoes`)} carga_item={carga_item} key={carga_item.nota_numero} />
          ))
        }

        {getItens().length === 0 && !isLoading && !filtro &&
          <CargaFinalizada />
        }
      </>
    </Layout>
  )
}

export default CargaItemList;