import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../app/mainReducer";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { loginActions } from "./reducer";
import { Formik } from 'formik';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { Form } from "formik";
import { ButtonForm, InputField } from "../../components";
import getEnv from "../../utils/getEnv";

export const Login: React.FC<{}> = () => {
  const parceiro = useSelector((state: RootState) => state.login?.parceiro);
  const eTransbordo = useSelector((state: RootState) => state.login?.eTransbordo);
  const isLoggingIn = useSelector(
    (state: RootState) => state.login.isLoggingIn
  );
  const error = useSelector((state: RootState) => state.login.error);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!parceiro) {
      if (eTransbordo) {
        navigate("/transbordos");
        return;
      }
      navigate("/");
      return;
    }
  }, [parceiro, navigate]);

  useEffect(() => {
    dispatch(loginActions.setIsLoggingIn(false));
  }, [dispatch]);


  return (
    <Flex maxW="30rem" minH="100vh" margin="auto" direction="column">
      <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
        {
          error && (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle>{error}</AlertTitle>
            </Alert>
          )
        }
        <Flex
          direction="column"
          bgColor="blue.600"
          color="white"
          py="2rem"
          px="1rem"
          marginTop="1rem"
          borderRadius="3px"
        >
          <Heading mx="auto"> Gestão de Entregas </Heading>
          <Heading mx="auto" size="sm">{getEnv('REACT_APP_EMPRESA_NOME')}</Heading>
          <Formik
            initialValues={{ cpf: "", senha: "" }}
            onSubmit={(values, { setErrors }) => {
              let errors = {};

              if (!values.cpf) {
                errors = {
                  ...errors,
                  cpf: 'Campo obrigatório'
                }
              }

              if (!values.senha) {
                errors = {
                  ...errors,
                  senha: 'Campo obrigatório'
                }
              }

              if (Object.keys(errors).length > 0) {
                setErrors(errors);
                return;
              }

              console.log("login form", values);
              dispatch(loginActions.loginRequest(values));
            }}
          >
            <Form>
              <InputField bgNone={true} label="CPF/CNPJ" name="cpf" autoFocus type='tel' placeholder="Seu CPF ou CNPJ" />
              <InputField bgNone={true} label="Número do Romaneio ou Carga" name="senha" type='tel' placeholder="Número do Romaneio ou Carga" />
              <ButtonForm
                isLoading={isLoggingIn}
                colorScheme="teal"
                size="lg"
                mt="1rem"
                type="submit"
                style={{ width: '100%' }}
              >
                Entrar
              </ButtonForm>
            </Form>
          </Formik>
        </Flex>
        <Box paddingX={2}>
          <small>
            Ao continuar, você concorda com nossa política de Cookies e termos de tratamento de dados de acordo com a nossa política de privacidade.
            <br />
            {getEnv('REACT_APP_TERMO_PRIVACIDADE') && <a style={{ color: 'blue' }} href={getEnv('REACT_APP_TERMO_PRIVACIDADE')} target="_blank">{getEnv('REACT_APP_TERMO_PRIVACIDADE')}</a>}
          </small>
        </Box>
      </div>
    </Flex >
  );
};
