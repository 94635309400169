import { useDispatch } from "react-redux";
import { loginActions } from "../modules/login/reducer";

export function useChangeNetworkStatus() {
  const dispatch = useDispatch();

  if (navigator.onLine) {
    console.log('online')
    dispatch(loginActions.setNetworkStatus(true))
  }

  window.addEventListener("offline", function () {
    console.log('offline')
    dispatch(loginActions.setNetworkStatus(false))
  });

  window.addEventListener("online", function () {
    console.log('online')
    dispatch(loginActions.setNetworkStatus(true))
  });
}


