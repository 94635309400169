import { PayloadAction } from "@reduxjs/toolkit";
import { apiCall } from "../../utils/apiCall";
import { put, all, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import { formatError } from "../../utils/formatError";
import { transbordoActions } from "./reducer";

function* transbordoWorker({ payload }: PayloadAction<{ j14_ukey: string }>) {
  try {
    const res: AxiosResponse<any> = yield call(apiCall, {
      url: `/gestao-de-entrega/v2/transbordos/${payload.j14_ukey}`,
      method: "get",
    });
    yield put(transbordoActions.transbordoRequestSuccess(res.data));
  } catch (error) {
    yield put(
      transbordoActions.transbordoError(formatError(error))
    );
  }
}

function* transbordosWorker({ payload }: PayloadAction<{ j14_ukey: string }>) {
  try {
    const res: AxiosResponse<any> = yield call(apiCall, {
      url: `/gestao-de-entrega/v2/transbordos`,
      method: "get",
    });
    yield put(transbordoActions.transbordosRequestSuccess(res.data));
  } catch (error) {
    yield put(
      transbordoActions.transbordoError(formatError(error))
    );
  }
}

function* salvarGestaoEntregaRequestWorker({ payload }: PayloadAction<{ j14_ukey: string }>) {
  try {
    const res: AxiosResponse<any> = yield call(apiCall, {
      url: '/gestao-de-entrega/v2/transbordos/enviar',
      method: "post",
      data: payload,
    });
    yield put(transbordoActions.salvarGestaoEntregaRequestSuccess(res.data));
  } catch (error) {
    yield put(
      transbordoActions.transbordoError(formatError(error))
    );
  }
}


export function* transbordoSaga() {
  yield all([
    takeLatest("transbordo/transbordoRequest", transbordoWorker),
    takeLatest("transbordo/transbordosRequest", transbordosWorker),
    takeLatest("transbordo/salvarGestaoEntregaRequest", salvarGestaoEntregaRequestWorker),
  ]);
}
