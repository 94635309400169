import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { GestaoEntrega } from "../entrega/types";
import { Carga } from "./types";

type TInitialState = {
  error?: string;
  success?: string;
  isLoading?: boolean;
  cargas?: Carga[];
  carga?: Carga;
};

const initialState: TInitialState = {};

const reducer = createSlice({
  name: "carga",
  initialState,
  reducers: {
    cargasError(state, { payload }: PayloadAction<string>) {
      state.error = payload;
    },
    cargasRequest(state) {
      state.isLoading = true;
      state.success = "";
      state.error = "";
    },
    cargasRequestSuccess(state, { payload }: PayloadAction<Carga[]>) {
      state.isLoading = false;
      state.cargas = payload;
      state.error = "";
      state.success = "";
    },
    cargaRequest(state, _: PayloadAction<{ ukey: string }>) {
      state.isLoading = true;
      state.success = "";
      state.error = "";
    },
    cargaRequestSuccess(state, { payload }: PayloadAction<Carga>) {
      state.isLoading = false;
      state.carga = payload;
      state.error = "";
      state.success = "";

      // Para casos que busca dados novamente da carga, atualiza a listagem
      const cargaIndex = (state.cargas || []).findIndex(carga => carga.j14_ukey === payload.j14_ukey);

      if (cargaIndex < 0) return;
      if (!state.cargas) return;

      state.cargas[cargaIndex] = payload;
    },
    retiraNotaDaCarga(state, { payload }: PayloadAction<GestaoEntrega>) {
      const j81_ukeyp = payload.j81_ukeyp;
      const j81_par = payload.j81_par;

      // Se for transbordo, retira da listagem
      if (j81_par == 'J14') {
        const transbordoIndex = (state?.cargas || []).findIndex((i) => i.j14_ukey == j81_ukeyp);

        if (transbordoIndex < 0) return;
        if (!state.cargas) return;

        state.cargas.splice(transbordoIndex, 1);
        return;
      }

      const notaIndex = (state.carga?.itens || []).findIndex(item => item.j10_ukey == j81_ukeyp);

      if (notaIndex < 0) return;
      if (!state.carga) return;

      // Retira nota da listagem
      state.carga.itens.splice(notaIndex, 1);

      // Atualiza a mesma carga só que na listagem
      const cargaIndex = (state.cargas || []).findIndex(carga => carga.j14_ukey == state.carga?.j14_ukey);

      if (!state.cargas) return;
      if (cargaIndex < 0) return;

      state.cargas[cargaIndex] = state.carga;

      // Carga finalizada, retira ela da listagem
      if (state.carga.itens.length === 0) {
        state.cargas.splice(cargaIndex, 1);
        return;
      }
    },
    marcaNotaDaCargaReentrega(state, { payload }: PayloadAction<GestaoEntrega>) {
      const j81_ukeyp = payload.j81_ukeyp;
      const j81_par = payload.j81_par;

      if (j81_par == 'J14') {
        const transbordoIndex = (state.cargas || []).findIndex(item => item.j14_ukey == j81_ukeyp);

        if (transbordoIndex < 0) return;
        if (!state.cargas) return;

        state.cargas[transbordoIndex] = {
          ...state.cargas[transbordoIndex],
          e_reentrega: '1',
        }

        return;
      }

      const notaIndex = (state.carga?.itens || []).findIndex(item => item.j10_ukey == j81_ukeyp);

      if (notaIndex < 0) return;
      if (!state.carga) return;

      // Retira nota da listagem
      state.carga.itens[notaIndex] = {
        ...state.carga.itens[notaIndex],
        e_reentrega: '1',
      };

      // Atualiza a mesma carga só que na listagem
      const cargaIndex = (state.cargas || []).findIndex(carga => carga.j14_ukey == state.carga?.j14_ukey);

      if (!state.cargas) return;
      if (cargaIndex < 0) return;

      state.cargas[cargaIndex] = state.carga;
    },
  },
});

export const cargaActions = reducer.actions;

export default reducer.reducer;
