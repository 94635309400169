import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../modules/app/mainReducer";
import { entregaActions } from "../modules/entrega/reducer";

export function useSendPendentes() {
  const dispatch = useDispatch();
  const gestao_entrega_pendentes = useSelector((state: RootState) => state.entrega.gestao_entrega_pendentes);
  const localizacoes_pendentes = useSelector((state: RootState) => state.entrega.localizacoes_pendentes);
  const networkOnline = useSelector((state: RootState) => state.login.networkOnline);
  const location = useLocation();

  useEffect(() => {
    if (!networkOnline) return;

    if (gestao_entrega_pendentes) {
      for (const gestao of gestao_entrega_pendentes) {
        if (gestao) {
          dispatch(entregaActions.requestEnviaGestaoEntrega(gestao));
        }
      }
    }

    if (localizacoes_pendentes) {
      for (const localizacao of localizacoes_pendentes) {
        if (localizacao) {
          dispatch(entregaActions.requestEnviaLocalizacaoMotorista(localizacao));
        }
      }
    }
  }, [location])
}
