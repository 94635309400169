import { Button, Text } from "@chakra-ui/react";
import { FiPhone } from "react-icons/fi";
import { formatCelular } from "../../../utils/formatCelular";

const CelularClienteButton = ({ celular }: { celular: string }) => {
  return (
    <>
      <small>
        Cel:
        <a
          id={`input-tel${celular}`}
          style={{ marginLeft: '2px' }}
          href={`tel:+55${celular}`}
        >
          {formatCelular(celular)}
          <Button ml={1} size="xs" colorScheme="blackAlpha">
            <FiPhone /> <Text ml={1}>Ligar</Text>
          </Button>
        </a>
      </small> <br />
    </>
  )
}

export default CelularClienteButton;