import { PayloadAction } from "@reduxjs/toolkit";
import { apiCall } from "../../utils/apiCall";
import { put, all, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import { formatError } from "../../utils/formatError";
import { RecolocarImagemPayload } from "./types/recolocar_imagem_payload";
import { imagensRejeitadasActions } from "./reducer";

function* recolocarImagem1Worker({ payload }: PayloadAction<RecolocarImagemPayload>) {
  try {
    const res: AxiosResponse<any> = yield call(apiCall, {
      url: `/gestao-de-entrega/v2/imagens-rejeitadas/recolocar-imagem-1/${payload.j81_ukey}`,
      method: "post",
      data: {
        imagem: payload.imagem,
      }
    });
    yield put(imagensRejeitadasActions.success("Imagem enviada com sucesso!"));
  } catch (error) {
    yield put(
      imagensRejeitadasActions.error(formatError(error))
    );
  }
}

function* recolocarImagem2Worker({ payload }: PayloadAction<RecolocarImagemPayload>) {
  try {
    const res: AxiosResponse<any> = yield call(apiCall, {
      url: `/gestao-de-entrega/v2/imagens-rejeitadas/recolocar-imagem-2/${payload.j81_ukey}`,
      method: "post",
      data: {
        imagem: payload.imagem,
      }
    });
    yield put(imagensRejeitadasActions.success("Imagem enviada com sucesso!"));
  } catch (error) {
    yield put(
      imagensRejeitadasActions.error(formatError(error))
    );
  }
}

function* imagensRejeitadasWorker() {
  try {
    const res: AxiosResponse<any> = yield call(apiCall, {
      url: `/gestao-de-entrega/v2/imagens-rejeitadas`,
      method: "get",
    });
    yield put(imagensRejeitadasActions.imagensRejeitadasRequestSuccess(res.data));
  } catch (error) {
    yield put(
      imagensRejeitadasActions.error(formatError(error))
    );
  }
}

function* imagensRejeitadasTransbordoWorker() {
  try {
    const res: AxiosResponse<any> = yield call(apiCall, {
      url: `/gestao-de-entrega/v2/imagens-rejeitadas-transbordo`,
      method: "get",
    });
    yield put(imagensRejeitadasActions.imagensRejeitadasTransbordoRequestSuccess(res.data));
  } catch (error) {
    yield put(
      imagensRejeitadasActions.error(formatError(error))
    );
  }
}

export function* imagensRejeitadasSaga() {
  yield all([
    takeLatest("imagens-rejeitadas/recolocarImagem1Request", recolocarImagem1Worker),
    takeLatest("imagens-rejeitadas/recolocarImagem2Request", recolocarImagem2Worker),
    takeLatest("imagens-rejeitadas/imagensRejeitadasRequest", imagensRejeitadasWorker),
    takeLatest("imagens-rejeitadas/imagensRejeitadasTransbordoRequest", imagensRejeitadasTransbordoWorker),
  ]);
}
