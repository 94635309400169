import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

export function getDateTime(): string {
  try {
    return format(new Date(), 'yyyy-MM-dd HH:mm:ss', {
      locale: ptBR,
    });
  } catch (error) {
    return "";
  }
}