import { Button, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spacer, useDisclosure } from "@chakra-ui/react";
import { FiCheck } from "react-icons/fi";

interface IProps {
  url: string;
  label: string;
  color?: string;
}

const InputVerImagem = ({ url, label, color = 'teal' }: IProps) => {

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button mr={1} size="sm" colorScheme={color} onClick={onOpen}>{color != 'red' && <><FiCheck /> &nbsp;</>} {label}</Button>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Visualização da imagem</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src={url} alt='imagem' />
            <Spacer marginY={5} />
            <Button colorScheme="teal" onClick={onClose}>Fechar</Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default InputVerImagem;