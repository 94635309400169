import { Heading } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import { useChangeNetworkStatus } from "../../hooks/useChangeNetworkStatus";
import { CargaItemList, CargaList } from "../carga/views";
import { OpcoesEntrega, RegistrarClienteAusente, RegistrarDevolucao, RegistrarEntrega } from "../entrega/views";
import { Login } from "../login/login";
import { NotFound } from "../sistema/views";
import { TransbordoEdit, TransbordoList } from "../transbordo/views";
import useIsMobile from "../../hooks/useIsMobile";
import { ImagensRejeitadas } from "../imagens-rejeitadas/views/ImagensRejeitadas";

export const Router: React.FC<{}> = () => {
  useChangeNetworkStatus();
  useIsMobile();

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<CargaList />} />
      <Route path="/cargas" element={<CargaList />} />
      <Route path="/cargas/:ukey" element={<CargaItemList />} />
      <Route path="/entrega/:tipo/:ukey/opcoes" element={<OpcoesEntrega />} /> {/* tipo = transbordo */}
      <Route path="/entrega/:tipo/:ukey/opcoes/cliente-ausente" element={<RegistrarClienteAusente />} /> {/* tipo = transbordo */}
      <Route path="/entrega/:tipo/:ukey/opcoes/devolucao" element={<RegistrarDevolucao />} /> {/* tipo = transbordo */}
      <Route path="/entrega/:tipo/:ukey/opcoes/entregar" element={<RegistrarEntrega />} /> {/* tipo = transbordo */}
      <Route path="/entrega/:tipo/:ukey/:j10_ukey/opcoes" element={<OpcoesEntrega />} /> {/* tipo = nota */}
      <Route path="/entrega/:tipo/:ukey/:j10_ukey/opcoes/cliente-ausente" element={<RegistrarClienteAusente />} /> {/* tipo = nota */}
      <Route path="/entrega/:tipo/:ukey/:j10_ukey/opcoes/devolucao" element={<RegistrarDevolucao />} /> {/* tipo = nota */}
      <Route path="/entrega/:tipo/:ukey/:j10_ukey/opcoes/entregar" element={<RegistrarEntrega />} /> {/* tipo = nota */}
      <Route path="/transbordos" element={<TransbordoList />} />
      <Route path="/transbordos/:j14_ukey" element={<TransbordoEdit />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/imagens-rejeitadas" element={<ImagensRejeitadas />} />
    </Routes>
  );
};
