import { Box, Center, Flex, FormLabel, Heading, Input, Skeleton, useMediaQuery } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ButtonBack, Error, Layout } from "../../../components";
import { RootState } from "../../app/mainReducer";
import { CargaFinalizada } from "../../carga/components";
import { OpcoesDownloadCarga, TransbordoItem } from "../components";
import { transbordoActions } from "../reducer";

const TransbordoEdit = () => {
  const isLoading = useSelector((state: RootState) => state.transbordo.isLoading);
  const transbordo = useSelector((state: RootState) => state.transbordo.transbordo);
  const error = useSelector((state: RootState) => state.transbordo.error || '');
  const networkOnline = useSelector((state: RootState) => state.login.networkOnline || false);

  const { j14_ukey } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!j14_ukey) return;

    if (networkOnline) {
      dispatch(transbordoActions.transbordoRequest({ j14_ukey }));
    } else {
      dispatch(transbordoActions.transbordoError('Não é possível buscar as entregas, você está sem internet.'));
    }
  }, [dispatch, transbordoActions, j14_ukey])

  const search = () => {
    if (networkOnline) {
      if (!j14_ukey) return;

      dispatch(transbordoActions.transbordoRequest({ j14_ukey }));
    }
  }
  const [filtro, setFiltro] = useState("");


  const getItens = () => {
    if (!transbordo?.itens) return [];

    if (!filtro) return transbordo?.itens;

    return transbordo.itens.filter((i) => {
      return i.cliente_fantasia.toLowerCase().includes(filtro.toLowerCase()) ||
        i.cliente_razao_social.toLowerCase().includes(filtro.toLowerCase()) ||
        i.nota_numero.toLowerCase().includes(filtro.toLowerCase())
    });
  }

  const [eMobile] = useMediaQuery('(max-width: 768px)')

  return (
    <Layout maxWTop="600px" backTo="/transbordos" title={<ButtonBack to="/transbordos" />} maxW="1200px" isLoading={isLoading} onSearch={search}>
      <>
        <Flex width="full" wrap="wrap">
          <Flex width={eMobile ? '100%' : '20%'} direction="column" mt={5} height="full" position="relative">
            {isLoading && <Skeleton rounded="md" height='380px' mb={1} />}
            {!isLoading && <OpcoesDownloadCarga j14_ukey={j14_ukey as string} j38_ukey={transbordo?.j38_ukey as string} />}
          </Flex>

          <Box width={eMobile ? '100%' : '80%'}>
            <Center mt={1} mb={2}>
              <Heading>Transbordo {transbordo?.numero}</Heading>
            </Center>
            <Center mt={1} mb={2}>
              <Heading size="md">{transbordo?.transbordo_nome}</Heading>
            </Center>
            <Center mt={1} mb={2}>
              <Heading size="sm" my={4}>
                Este é um painel de realização das entregas da carga {transbordo?.numero}.
              </Heading>
            </Center>

            <Error error={error} />

            {getItens().length === 0 && !isLoading && !filtro &&
              <CargaFinalizada />
            }


            {(getItens().length > 0 || (getItens().length == 0 && filtro)) &&
              <Flex width="full" wrap="wrap">
                <FormLabel mx={2}>Filtrar pelo número da Nota ou Cliente</FormLabel>
                <Input mx={2} mb={4} bg="white" placeholder="Digite o número da nota ou nome do cliente" value={filtro} onChange={(e) => setFiltro(e.target.value)} />
              </Flex>
            }
            {
              isLoading && [1, 2, 3, 4, 5, 6].map((i) => (
                <Skeleton mx={2} height='110px' mb={1} key={i} />
              ))
            }
            {
              !isLoading && getItens().map((transbordo_item) => (
                <TransbordoItem item={transbordo_item} key={transbordo_item.j10_ukey} />
              ))
            }
          </Box>
        </Flex>
      </>
    </Layout>
  )
}

export default TransbordoEdit;