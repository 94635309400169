import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { GestaoEntrega } from "../entrega/types";
import { Transbordo } from "./types";

type TInitialState = {
  error?: string;
  success?: string;
  isLoading?: boolean;
  isLoadingItem?: boolean;
  transbordos?: Transbordo[];
  transbordo?: Transbordo;
};

const initialState: TInitialState = {};

const reducer = createSlice({
  name: "transbordo",
  initialState,
  reducers: {
    transbordoSuccess(state, { payload }: PayloadAction<string>) {
      state.success = payload;
      state.error = '';
      state.isLoadingItem = false;
      state.isLoading = false;
    },
    transbordoError(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.success = '';
      state.isLoading = false;
      state.isLoadingItem = false;
    },
    transbordoRequest(state, _: PayloadAction<{ j14_ukey: string }>) {
      state.isLoading = true;
      state.success = "";
      state.error = "";
    },
    transbordoRequestSuccess(state, { payload }: PayloadAction<Transbordo>) {
      state.isLoading = false;
      state.transbordo = payload;
      state.error = "";
      state.success = "";
    },
    transbordosRequest(state, _: PayloadAction<void>) {
      state.isLoading = true;
      state.success = "";
      state.error = "";
    },
    transbordosRequestSuccess(state, { payload }: PayloadAction<Transbordo[]>) {
      state.isLoading = false;
      state.transbordos = payload;
      state.error = "";
      state.success = "";
    },
    salvarGestaoEntregaRequest(state, _: PayloadAction<GestaoEntrega>) {
      state.isLoadingItem = true;
      state.error = '';
      state.success = '';
    },
    salvarGestaoEntregaRequestSuccess(state, { payload }: PayloadAction<GestaoEntrega>) {
      state.error = '';
      state.isLoadingItem = false;
      state.success = 'Entrega realizada com sucesso.';

      if (!state.transbordo) return;

      const notaIndex = (state.transbordo.itens || []).findIndex(e => e.j10_ukey == payload.j81_ukeyp);

      if (notaIndex < 0) return;

      if (payload.e_tentativa_entrega == 1) {
        state.transbordo.itens[notaIndex].e_reentrega = '1';
      } else {
        state.transbordo.itens[notaIndex].j81_ukey = payload.ukey as string;
      }
      state.transbordo.itens[notaIndex].gestao_data_entrega = payload.data_entrega;
      state.transbordo.itens[notaIndex].gestao_observacoes = payload.observacoes;
      state.transbordo.itens[notaIndex].gestao_url_imagem1 = payload.url_imagem1;
      state.transbordo.itens[notaIndex].gestao_url_imagem2 = payload.url_imagem2;
    },
  },
});

export const transbordoActions = reducer.actions;

export default reducer.reducer;
