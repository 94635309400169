import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { entregaActions } from "../modules/entrega/reducer";

export function useGeolocalizacaoAtual() {
  const dispatch = useDispatch();
  const { ukey } = useParams();

  useEffect(() => {
    const interval = setInterval(() => {
      if (!ukey) return;

      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords

        dispatch(entregaActions.registraGeolocalizacao({
          latitude,
          longitude,
        }));

        dispatch(entregaActions.registraNovaLocalizacaoMotorista(ukey))
      });
    }, 1000 * 60 * 10); // 1000 * 60 * 4 - 4 minutos

    return () => clearInterval(interval);
  }, []);
}
