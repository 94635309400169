import { Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { differenceInMinutes, differenceInSeconds } from 'date-fns'
import { useState } from 'react';

const Temporizador = () => {
  const [data, setData] = useState(new Date());
  const gestao_entrega_temporario = useSelector((state: RootState) => state.entrega.gestao_entrega_temporario);

  const getSeconds = () => {
    const diff = differenceInSeconds(data, new Date(gestao_entrega_temporario?.data_inicio as string)) % 60;

    if (diff < 10) return `0${diff}`;

    return diff;
  }

  const getMinutes = () => {
    const diff = differenceInMinutes(data, new Date(gestao_entrega_temporario?.data_inicio as string));

    if (diff < 10) return `0${diff}`;

    return diff;
  }

  setTimeout(() => {
    setData(new Date());
  }, 1000);

  return (
    <Text my="auto" fontSize={18} fontWeight="bold" color="white">
      {getMinutes()}:{getSeconds()}
    </Text>
  )
}

export default Temporizador;