import { Button, Flex, FormControl, FormErrorMessage, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spacer, useDisclosure } from "@chakra-ui/react";
import { useField } from "formik";
import { FiCamera, FiCheck, FiX } from "react-icons/fi";
import { useEffect, useState } from 'react';

interface IProps {
  name: string;
  label: string;
  mb?: number;
}

const ImageCameraButton = ({ name, label, mb = 0 }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenTirarFoto, onOpen: onOpenTirarFoto, onClose: onCloseTirarFoto } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false);
  const [, { error, touched, value }, { setValue }] = useField(name);

  const onOpenTirarFotoFunction = () => {
    onOpenTirarFoto();
    setIsLoading(true);
    setTimeout(() => {
      const video = document.getElementById("video") as HTMLVideoElement;
      const captureButton = document.getElementById(
        "capture-btn"
      ) as HTMLButtonElement;
      const canvas: any = document.getElementById("canvas") as HTMLCanvasElement

      if ((video.srcObject as any)?.getTracks()) {
        (video.srcObject as any)?.getTracks().forEach((track: any) => track.stop());
      }
      console.log('onOpenTirarFotoFunction -> navigator.mediaDevices');
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: "environment",
          }
        })
        .then(function (stream) {
          video.srcObject = stream;
          console.log('onOpenTirarFotoFunction -> navigator.mediaDevices true');
          setTimeout(() => {
            setIsLoading(false);
          }, 100)
        });

      captureButton.addEventListener("click", function () {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);

        setValue(canvas.toDataURL("image/jpeg"));
        if ((video.srcObject as any)?.getTracks()) {
          (video.srcObject as any)?.getTracks().forEach((track: any) => track.stop());
        }
        onCloseTirarFoto()
      });
    }, 500)
  }

  const onCloseModal = () => {
    const video = document.getElementById("video") as HTMLVideoElement;
    if (video && video?.srcObject && (video?.srcObject as any)?.getTracks()) {
      (video.srcObject as any)?.getTracks().forEach((track: any) => track.stop());
    }
    onCloseTirarFoto()
  }

  return (
    <Flex>
      <FormControl mb={mb} isInvalid={touched && error ? true : false}>
        <Button size="sm" mr={1} borderWidth={error && '2px'} borderColor="red" colorScheme="teal" onClick={onOpenTirarFotoFunction}><FiCamera /> &nbsp; {label}</Button>
        {value && <Button mr={1} size="sm" colorScheme="teal" onClick={onOpen}><FiCheck /> &nbsp; Ver</Button>}
        {value && <Button size="sm" colorScheme="red" onClick={() => setValue('')}><FiX /> </Button>}
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
      <Modal blockScrollOnMount={false} autoFocus={false} returnFocusOnClose={false} closeOnOverlayClick={false} isOpen={isOpenTirarFoto} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Tirar Foto <Button colorScheme="blackAlpha" onClick={onCloseModal}>Voltar</Button></ModalHeader>
          <ModalBody>
            <Button
              id="capture-btn"
              colorScheme="teal"
              width={"full"}
              my={2}
            > {isLoading ? 'Abrindo camera, aguarde...' : 'Tirar Foto'}</Button>
            <video id="video" autoPlay playsInline></video>
            <canvas id="canvas" style={{ display: 'none' }}></canvas>
            <Spacer marginY={5} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Pré visualização da imagem</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              <Image src={value} alt='imagem' width={"auto"} height={"auto"} />
            </Flex>
            <Spacer marginY={5} />
            <Button colorScheme="teal" onClick={onClose}>Fechar</Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default ImageCameraButton;