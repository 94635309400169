import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { formatDate } from "../../../utils/formatDate";
import { Transbordo as TransbordoType } from "../types";

interface IProps {
  transbordo: TransbordoType;
  onClick: () => void;
}

const Transbordo = ({ transbordo, onClick }: IProps) => {
  return (
    <Box onClick={onClick} _hover={{ backgroundColor: '#f7f7f7' }} cursor="pointer" shadow="md" bg="white" mb={1}>
      <Flex justifyContent="space-between">
        <Box p={3}>
          <Heading size="md">Carga: {transbordo.numero} - {formatDate(transbordo.data_emissao)} </Heading>
          <Text>Total entregas: {transbordo.qt_pedidos} | Em aberto: {transbordo.itens.filter((i) => !i.j81_ukey).length}</Text>
        </Box>
      </Flex>
    </Box>
  )
}


export default Transbordo;