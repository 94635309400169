import { Badge, Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { formatCep } from "../../../utils/formatCep";
import { formatDate } from "../../../utils/formatDate";
import { Carga as CargaType } from "../types";
import CelularClienteButton from "./CelularClienteButton";
import DadosComplementaresButton from "./DadosComplementaresButton";
import RotaButton from "./RotaButton";

interface IProps {
  carga: CargaType;
}

const CargaTransbordo = ({ carga }: IProps) => {
  const navigate = useNavigate();

  return (
    <Box _hover={{ backgroundColor: '#f7f7f7' }} shadow="md" bg="white" mb={1}>
      <Box p={3}>
        <Heading size="md">Transbordo: {carga.numero} - {formatDate(carga.data_emissao)} </Heading>
        {carga.e_reentrega == '1' && <><Badge colorScheme="green" mr={1}>REENTREGA</Badge> <br /></>}
        <small>Nome: {carga.transbordo_nome}</small> <br />
        <small>Endereço: {carga.transbordo_endereco}, n° {carga.transbordo_numero}</small><br />
        <small>Cidade: {carga.transbordo_cidade} ({formatCep(carga.transbordo_cep)}) {carga.transbordo_latitude && <RotaButton latitude={carga.transbordo_latitude} longitude={carga.transbordo_longitude} />}</small> <br />
        {carga?.transbordo_celular && <CelularClienteButton celular={carga?.transbordo_celular} />}
        <Flex width="full" justifyContent="space-between" mt={1}>
          <DadosComplementaresButton dados_complementares={carga} pessoa={carga.transbordo_nome} />
          <Button onClick={() => navigate(`/entrega/transbordo/${carga.j14_ukey}/opcoes`)} size="sm" colorScheme="green"><Text mr={1}>ENTREGAR</Text> <FiArrowRight fontSize="1.3rem" /></Button>
        </Flex>
      </Box>
    </Box>
  )
}


export default CargaTransbordo;