import { Box, Flex, Heading } from "@chakra-ui/react";
import { ButtonForm, InputVerImagem } from "../../../components";
import { TransbordoItem } from "../../transbordo/types";
import { Form, Formik } from "formik";
import { RecolocarImagemPayload } from "../types/recolocar_imagem_payload";
import InputImage from "../../../components/InputImage";
import { FiArrowUp } from "react-icons/fi";
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useEffect, useState } from "react";

type ReenviarImagemFormProps = {
  fotoAtual: string;
  imagemRejeitada: TransbordoItem;
  dispatchForm: (values: RecolocarImagemPayload) => void;
  label: string;
};

export const ReenviarImagemForm = ({
  fotoAtual,
  imagemRejeitada,
  dispatchForm,
  label,
}: ReenviarImagemFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const error = useSelector((state: RootState) => state.imagensRejeitadas?.error);
  const success = useSelector((state: RootState) => state.imagensRejeitadas?.success);

  useEffect(() => {
    if (error || success) {
      setIsLoading(false);
    }
  }, [error, success]);

  return (
    <Flex width={"full"} justifyContent={"center"} wrap={"wrap"} mt={6}>
      <Box width={"full"}>
        <Heading size={"sm"} mx={"auto"} textAlign={"center"} color={"gray.600"}>
          {label}
        </Heading>
      </Box>
      <InputVerImagem label="Ver foto rejeitada" color="red" url={fotoAtual} />

      <Box width={"full"} mt={2} />

      <Formik
        initialValues={
          {
            j81_ukey: imagemRejeitada.j81_ukey,
            imagem: "",
          } as RecolocarImagemPayload
        }
        onSubmit={(values, { setErrors }) => {
          let errors = {};

          if (!values.imagem) {
            errors = {
              imagem: "Campo obrigatório",
            };
          }

          if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
          }

          console.log("form", values);
          setIsLoading(true);
          dispatchForm(values);
        }}
      >
        <Form>
          <InputImage label="Adicione a nova foto" name="imagem" />

          <ButtonForm
            mt={1}
            isLoading={isLoading}
            colorScheme="blue"
            size="lg"
            type="submit"
            width={"full"}
            display={"flex"}
            justifyContent={"center"}
            gap={1}
            alignItems={"center"}
          >
            <FiArrowUp /> Enviar nova foto
          </ButtonForm>
        </Form>
      </Formik>
    </Flex>
  );
};
