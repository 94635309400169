import { PayloadAction } from "@reduxjs/toolkit";
import { APIURL } from "../../utils/apiCall";
import { put, all, takeLatest } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import { loginActions } from "./reducer";
import { formatError } from "../../utils/formatError";
import { Parceiro } from "./types";

interface ILogin { cpf: string; senha: string };

export interface TLoginRes {
  parceiro: Parceiro;
  eTransbordo: boolean;
};

function loginCall(payload: ILogin) {
  console.log(`${APIURL}/gestao-de-entrega/v2/login`)
  return axios.post(`${APIURL}/gestao-de-entrega/v2/login`, {
    cpf: payload.cpf,
    senha: payload.senha,
  });
}

function* loginWorker({
  payload,
}: PayloadAction<ILogin>) {
  try {
    const res: AxiosResponse<TLoginRes> = yield call(loginCall, payload);
    yield put(loginActions.loginSuccess(res.data));
  } catch (error) {
    yield put(
      loginActions.loginError(formatError(error))
    );
  }
}

export function* loginSaga() {
  yield all([
    takeLatest("login/loginRequest", loginWorker),
  ]);
}
