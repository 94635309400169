import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { TLoginRes } from "./saga";
import { Parceiro } from "./types";

type TInitialState = {
  parceiro?: Parceiro | null;
  eTransbordo?: boolean;
  isLoggingIn?: boolean;
  error?: string;
  networkOnline: boolean;
  isMobile: boolean;
};

const initialState: TInitialState = {
  networkOnline: true,
  isMobile: true,
};

const reducer = createSlice({
  name: "login",
  initialState,
  reducers: {
    setIsLoggingIn(state, { payload }: PayloadAction<boolean>) {
      state.isLoggingIn = payload;
    },
    loginRequest(state, _: PayloadAction<{ cpf: string; senha: string }>) {
      state.isLoggingIn = true;
      state.error = "";
    },
    loginSuccess(state, { payload }: PayloadAction<TLoginRes>) {
      state.isLoggingIn = false;
      state.parceiro = payload.parceiro;
      state.eTransbordo = payload.eTransbordo;
    },
    loginError(state, { payload }: PayloadAction<string>) {
      state.isLoggingIn = false;
      state.error = payload;
    },
    logout(state) {
      state.parceiro = null;
      state.error = "";
    },
    setNetworkStatus(state, { payload }: PayloadAction<boolean>) {
      state.networkOnline = payload;
    },
    setaIsMobile(state, { payload }: PayloadAction<boolean>) {
      state.isMobile = payload;
    },
  },
});

export const loginActions = reducer.actions;

export default reducer.reducer;
