import { Button, Center, Flex, Heading, Input, Skeleton } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Error, Layout } from "../../../components";
import { useIsAuth } from "../../../hooks/useIsAuth"
import { RootState } from "../../app/mainReducer";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { InfoSemCarga } from "../../carga/components";
import { Transbordo } from "../components";
import { Transbordo as TransbordoType } from "../types";
import { transbordoActions } from "../reducer";
import { imagensRejeitadasActions } from "../../imagens-rejeitadas/reducer";

const TransbordoList = () => {
  useIsAuth();
  const isLoading = useSelector((state: RootState) => state.transbordo.isLoading);
  const transbordos = useSelector((state: RootState) => state.transbordo.transbordos);
  const error = useSelector((state: RootState) => state.transbordo.error || '');
  const networkOnline = useSelector((state: RootState) => state.login.networkOnline || false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!transbordos || transbordos.length === 0 || networkOnline) {
      dispatch(transbordoActions.transbordosRequest());
      dispatch(imagensRejeitadasActions.imagensRejeitadasTransbordoRequest());
    }
  }, [dispatch, transbordoActions])

  const search = () => {
    if (networkOnline) {
      dispatch(transbordoActions.transbordosRequest());
      dispatch(imagensRejeitadasActions.imagensRejeitadasTransbordoRequest());
    } else {
      alert('Você está sem internet, não foi possível buscar os dados.');
    }
  }

  const [filtro, setFiltro] = useState('');

  const getCargasFiltradas = (): TransbordoType[] => {
    if (!transbordos) return [];

    if (!filtro.trim()) return transbordos;

    return transbordos.filter((i) => i.itens.filter((j) => j.nota_numero.includes(filtro.trim())).length > 0 || i.numero.includes(filtro.trim()))
  }

  const imagensRejeitadas = useSelector(
    (state: RootState) => state.imagensRejeitadas.imagensRejeitadas ?? []
  );

  const quantidadeFotosRejeitadas = imagensRejeitadas.reduce(
    (soma, registroAtual) => {
      let somaAtual = soma;
      if (registroAtual.gestao_imagem1_rejeitada == 1) {
        somaAtual++;
      }
      if (registroAtual.gestao_imagem2_rejeitada == 1) {
        somaAtual++;
      }
      return somaAtual;
    },
    0
  );

  return (
    <Layout canLogout={true} isLoading={isLoading} onSearch={search}>
        {quantidadeFotosRejeitadas > 0 && (
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          width={"full"}
          textAlign={"center"}
          mt={2}
        >
          Você possui {quantidadeFotosRejeitadas} fotos rejeitadas pela empresa.
          <Link to={"/imagens-rejeitadas"}>
            <Button colorScheme="blue">Clique aqui para reenviá-las</Button>
          </Link>
        </Flex>
      )}

      <Center mt={4}>
        <Heading>Suas cargas em aberto</Heading>
      </Center>
      <Center mb={2}>
        <Heading size="sm">Selecione uma carga (clique)</Heading>
      </Center>
      <Flex mb={4} width={"full"} wrap="wrap">
        <Input borderRadius={0} bg="white" placeholder="Busque aqui pelo número da nota ou pela carga" onChange={(evt) => setFiltro(evt.target.value)} />
      </Flex>
      <Error error={error} />
      {isLoading && [1, 2, 3, 4, 5, 6].map((i) => (
        <Skeleton height='90px' mb={1} key={i} />
      ))}
      {!isLoading && getCargasFiltradas().map((transbordo) => (
        <div key={transbordo.j14_ukey}>
          <Transbordo onClick={() => navigate(`/transbordos/${transbordo.j14_ukey}`)} transbordo={transbordo} />
        </div>
      ))}
      {!isLoading && getCargasFiltradas().length === 0 &&
        <InfoSemCarga />
      }
    </Layout>
  )
}

export default TransbordoList;