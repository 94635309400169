import { Button } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

const ButtonBack = ({ to = "" }) => {
  const navigate = useNavigate();

  return (
    <>{to &&
      <Button colorScheme="blue" onClick={() => navigate(to)}>Voltar</Button>}
      {!to &&
        <Button colorScheme="blue" onClick={() => navigate(-1)}>Voltar</Button>}
    </>
  )
}

export default ButtonBack;