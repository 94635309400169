import { PayloadAction } from "@reduxjs/toolkit";
import { put, all, takeLatest, call } from "redux-saga/effects";
import { GestaoEntrega, LocalizacaoMotorista } from "./types";
import { cargaActions } from "../carga/reducer";
import { AxiosResponse } from "axios";
import { apiCall } from "../../utils/apiCall";
import { formatError } from "../../utils/formatError";
import { entregaActions } from "./reducer";
import { Carga } from "../carga/types";

type IRequestEnviarGestaoResponse = {
  message: string;
}

function* registraNovoGestaoEntregaWorker({ payload }: PayloadAction<{ gestao: GestaoEntrega; carga: Carga }>) {
  if (payload.gestao.e_tentativa_entrega == 1) {
    yield put(cargaActions.marcaNotaDaCargaReentrega(payload.gestao));
    return;
  };

  yield put(cargaActions.retiraNotaDaCarga(payload.gestao));
}

function* requestEnviaGestaoEntregaWorker({ payload }: PayloadAction<GestaoEntrega>) {
  try {
    const res: AxiosResponse<IRequestEnviarGestaoResponse> = yield call(apiCall, {
      url: `/gestao-de-entrega/v2/enviar`,
      method: "post",
      data: payload,
    });
    if (res.data.message) {
      yield put(entregaActions.setSuccess(res.data.message));
    }
    yield put(entregaActions.retiraGestaoPendente(payload.j81_ukeyp));
  } catch (error) {
    yield put(
      entregaActions.setError(formatError(error))
    );
  }
}

function* requestEnviaLocalizacaoMotoristaWorker({ payload }: PayloadAction<LocalizacaoMotorista>) {
  try {
    yield call(apiCall, {
      url: `/gestao-de-entrega/v2/localizacao`,
      method: "post",
      data: payload,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* entregaSaga() {
  yield all([
    takeLatest("entrega/registraNovoGestaoEntrega", registraNovoGestaoEntregaWorker),
    takeLatest("entrega/requestEnviaGestaoEntrega", requestEnviaGestaoEntregaWorker),
    takeLatest("entrega/requestEnviaLocalizacaoMotorista", requestEnviaLocalizacaoMotoristaWorker),
  ]);
}
