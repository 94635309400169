import { Badge, Box, Button, Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { FiArrowRight } from "react-icons/fi";
import { formatCep } from "../../../utils/formatCep";
import { formatCnpj } from "../../../utils/formatCnpj";
import { formatDate } from "../../../utils/formatDate";
import { CargaItem as CargaItemType } from "../types";
import CelularClienteButton from "./CelularClienteButton";
import DadosComplementaresButton from "./DadosComplementaresButton";
import RotaButton from "./RotaButton";

interface IProps {
  carga_item: CargaItemType;
  onClick: () => void;
}

const CargaItem = ({ carga_item, onClick }: IProps) => {
  const getVendedor = () => {
    return {
      vendedor_codigo: carga_item.vendedor_codigo,
      vendedor_celular: carga_item.vendedor_celular,
      vendedor_nome: carga_item.vendedor_nome,
    }
  }

  return (
    <Box _hover={{ backgroundColor: '#f7f7f7' }} shadow="md" bg="white" mb={1}>
      <Flex justifyContent="space-between">
        <Box width="full" p={3}>
          <Heading size="md">
            Nota Fiscal: {carga_item.nota_numero}
          </Heading>
          {carga_item.e_reentrega == '1' && <Badge colorScheme="green" mr={1}>REENTREGA</Badge>}
          {carga_item.nota_financeiro_recebido_antecipadamente == '0' &&
            carga_item.pedido_tipo_documento == 'PIX' &&
            carga_item.pedido_entrada_e_saida_gera_financeiro == '1' &&
            <Badge colorScheme="red" mr={1}>AGUARDANDO PAGAMENTO PIX</Badge>
          }
          {carga_item.e_nota_pallet == '1' && <Badge colorScheme="yellow" mr={1}>NOTA PALLET</Badge>}
          <SimpleGrid columns={2} fontSize="0.9rem">
            <Box pr={1}>
              <small>Fantasia: {carga_item.cliente_fantasia}</small> <br />
              {carga_item?.cliente_celular &&
                <CelularClienteButton celular={carga_item?.cliente_celular} />
              }
              <small>Endereço: {carga_item.cliente_endereco}, n° {carga_item.cliente_numero} - {carga_item.cliente_bairro}</small> <br />
              {carga_item.cliente_latitude &&
                <RotaButton margin={false} latitude={carga_item.cliente_latitude} longitude={carga_item.cliente_longitude} />
              }
            </Box>
            <Box>
              <small>Razão Social: {carga_item.cliente_razao_social}</small> <br />
              <small>CNPJ: {formatCnpj(carga_item.cliente_cnpj)}</small> <br />
              <small>Pedido: {carga_item.pedido_numero}</small> <br />
              <small>Cidade: {carga_item.cliente_cidade} ({formatCep(carga_item.cliente_cep)})</small> <br />
            </Box>
          </SimpleGrid>
          <Flex width="full" justifyContent="space-between" mt={1}>
            <DadosComplementaresButton vendedor={getVendedor()} dados_complementares={carga_item} pessoa={carga_item.cliente_razao_social} />
            <Button onClick={onClick} size="sm" colorScheme="green"><Text mr={1}>ENTREGAR</Text> <FiArrowRight fontSize="1.3rem" /></Button>
          </Flex>
        </Box>
      </Flex >
    </Box >
  )
}

export default CargaItem;