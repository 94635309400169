import { useDispatch } from "react-redux";
import { entregaActions } from "../modules/entrega/reducer";

export function useGeolocalizacao() {
  const dispatch = useDispatch();

  navigator.geolocation.getCurrentPosition((position) => {
    const { latitude, longitude } = position.coords

    dispatch(entregaActions.registraGeolocalizacao({
      latitude,
      longitude,
    }))
  });
}
