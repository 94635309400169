import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Heading, Text, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { FiInfo } from "react-icons/fi";
import { formatCelular } from "../../../utils/formatCelular";
import { formatTime } from "../../../utils/formatTime";
import { DadosComplementares } from "../types";

interface IProps {
  dados_complementares: DadosComplementares | any;
  pessoa: string;
  vendedor?: {
    vendedor_codigo: string;
    vendedor_nome: string;
    vendedor_celular: string;
  } | null;
}

const Header = ({ label }: { label: string }) => {
  return (
    <Heading size="sm" borderBottom="1px" mb={1}>{label}</Heading>
  )
}

const CardInfo = ({ label, info, show }: { label: string; info: any; show: boolean; }) => {
  return (
    <>{show && (
      <>
        <Header label={label} />
        {info}
      </>
    )}
    </>
  )
}

const DadosComplementaresButton = ({ dados_complementares, pessoa, vendedor = null }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [eMobile] = useMediaQuery('(max-width: 768px)')

  const getDiasFechado = () => {
    let retorno = "";

    if (dados_complementares.complemento_nao_abre_segunda == '1') {
      retorno += "Segunda-feira";
    }
    if (dados_complementares.complemento_nao_abre_terca == '1') {
      if (retorno) {
        retorno += ", Terça-feira";
      } else {
        retorno = "Terça-feira";
      }
    }
    if (dados_complementares.complemento_nao_abre_quarta == '1') {
      if (retorno) {
        retorno += ", Quarta-feira";
      } else {
        retorno = "Quarta-feira";
      }
    }
    if (dados_complementares.complemento_nao_abre_quinta == '1') {
      if (retorno) {
        retorno += ", Quinta-feira";
      } else {
        retorno = "Quinta-feira";
      }
    }
    if (dados_complementares.complemento_nao_abre_sexta == '1') {
      if (retorno) {
        retorno += ", Sexta-feira";
      } else {
        retorno = "Sexta-feira";
      }
    }
    if (dados_complementares.complemento_nao_abre_sabado == '1') {
      if (retorno) {
        retorno += ", Sábado";
      } else {
        retorno = "Sábado";
      }
    }
    if (dados_complementares.complemento_nao_abre_domingo == '1') {
      if (retorno) {
        retorno += ", Domingo";
      } else {
        retorno = "Domingo";
      }
    }

    return retorno;
  }

  return (
    <>
      <Button size="sm" colorScheme="blue" onClick={onOpen}><FiInfo fontSize="1.3em" /> <Text ml={1}>DETALHES</Text></Button>
      <Drawer placement={eMobile ? 'bottom' : 'right'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth='1px'>
            Dados Complementares <br />
            <Text fontSize="0.8rem">{pessoa}</Text>
          </DrawerHeader>
          <DrawerBody pb={20}>
            <CardInfo
              label="Data/Hora Recebimento 1"
              info={`Das ${formatTime(dados_complementares.complemento_hora_recebimento_inicial_1)} até ${formatTime(dados_complementares.complemento_hora_recebimento_final_1)}`}
              show={!!dados_complementares.complemento_hora_recebimento_inicial_1}
            />
            <CardInfo
              label="Data/Hora Recebimento 2"
              info={`Das ${formatTime(dados_complementares.complemento_hora_recebimento_inicial_2)} até ${formatTime(dados_complementares.complemento_hora_recebimento_final_2)}`}
              show={!!dados_complementares.complemento_hora_recebimento_inicial_2}
            />
            <CardInfo
              label="Estabelecimento Fechado em"
              info={getDiasFechado()}
              show={!!getDiasFechado()}
            />
            <CardInfo
              label="Observações Para a Entrega"
              info={dados_complementares.complemento_observacao_entrega}
              show={!!dados_complementares.complemento_observacao_entrega}
            />
            <CardInfo
              label="Representante do Pedido"
              info={<>
                {vendedor?.vendedor_codigo} - {vendedor?.vendedor_nome} <br />
                Celular: {formatCelular(vendedor?.vendedor_celular as string)}
              </>}
              show={!!vendedor}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>

  )
}

export default DadosComplementaresButton;