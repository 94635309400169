import { Button, Flex, Text } from "@chakra-ui/react"
import { FiMapPin } from "react-icons/fi";
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";

interface IProps {
  latitude: string;
  longitude: string;
  margin?: boolean;
}

const RotaButton = ({ latitude, longitude, margin = true }: IProps) => {
  const geolocalizacao = useSelector((state: RootState) => state.entrega.geolocalizacao);

  const goToMaps = () => {
    if (!geolocalizacao?.latitude || !geolocalizacao?.longitude) {
      return;
    }

    const url = `https://www.google.com.br/maps/dir/${geolocalizacao.latitude},${geolocalizacao.longitude}/${latitude},${longitude}`;
    window.open(url, "_blank");
  }

  return (
    <Button mx={margin ? 1 : 0} size="xs" colorScheme="blackAlpha" onClick={goToMaps}>
      <Flex justify="center">
        <FiMapPin /> <Text ml={1}>COMO CHEGAR?</Text>
      </Flex>
    </Button>
  )
}

export default RotaButton;