import { Center, Heading } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../app/mainReducer";

interface IProps {
  label: string;
}

const AcaoHeader = ({ label }: IProps) => {
  const { tipo, ukey: j14_ukey, j10_ukey } = useParams();

  const e_transbordo = tipo === 'transbordo';
  const carga = useSelector((state: RootState) => state.carga.cargas?.find((i) => i.j14_ukey === j14_ukey));
  const nota = useSelector((state: RootState) => carga?.itens?.find((i) => i.j10_ukey === j10_ukey));

  return (
    <>
      <Center mt={8}>
        <Heading>{label}</Heading>
      </Center>
      <Center mx={4} mb={8} mt={2}>
        {e_transbordo && <Heading size="sm">Transbordo {carga?.numero}</Heading>}
        {!e_transbordo && <Heading size="sm">Nota Fiscal {nota?.nota_numero}</Heading>}
      </Center>
    </>
  )
}

export default AcaoHeader;