import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { TransbordoItem } from "../transbordo/types";
import { RecolocarImagemPayload } from "./types/recolocar_imagem_payload";
import toasts from "../../utils/toasts";

type TInitialState = {
  error?: string;
  success?: string;
  isLoading?: boolean;
  imagensRejeitadas?: TransbordoItem[];
};

const initialState: TInitialState = {};

const reducer = createSlice({
  name: "imagens-rejeitadas",
  initialState,
  reducers: {
    success(state, { payload }: PayloadAction<string>) {
      state.success = payload;
      state.error = '';
      state.isLoading = false;
      toasts.success(payload);
    },

    error(state, { payload }: PayloadAction<string>) {
      state.success = '';
      state.error = payload;
      state.isLoading = false;
      toasts.error(payload);
    },

    imagensRejeitadasRequest(state) {
      state.isLoading = true;
      state.success = "";
      state.error = "";
    },

    imagensRejeitadasRequestSuccess(state, { payload }: PayloadAction<TransbordoItem[]>) {
      state.isLoading = false;
      state.imagensRejeitadas = payload;
      state.error = "";
      state.success = "";
    },

    imagensRejeitadasTransbordoRequest(state) {
      state.isLoading = true;
      state.success = "";
      state.error = "";
    },

    imagensRejeitadasTransbordoRequestSuccess(state, { payload }: PayloadAction<TransbordoItem[]>) {
      state.isLoading = false;
      state.imagensRejeitadas = payload;
      state.error = "";
      state.success = "";
    },

    recolocarImagem1Request(state, _: PayloadAction<RecolocarImagemPayload>) {
      state.isLoading = true;
      state.error = '';
      state.success = '';
    },

    recolocarImagem2Request(state, _: PayloadAction<RecolocarImagemPayload>) {
      state.isLoading = true;
      state.error = '';
      state.success = '';
    },
  },
});

export const imagensRejeitadasActions = reducer.actions;

export default reducer.reducer;
