import { Button, ButtonProps, CircularProgress } from "@chakra-ui/react";

const ButtonForm: React.FC<{ isLoading?: boolean } & ButtonProps> = ({
  isLoading,
  disabled,
  children,
  ...props
}) => {
  return (
    <Button
      disabled={isLoading || disabled}
      opacity={isLoading || disabled ? "0.5" : "1"}
      isLoading={isLoading}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ButtonForm;