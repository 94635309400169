import axios from "axios";
import { AxiosRequestConfig } from "axios";
import { store } from "../modules/app/mainReducer";
import getEnv from "./getEnv";
import { CANCEL } from "redux-saga";

export const APIURL: string = getEnv("REACT_APP_API_HOST");
export const SAERPTRANSAPIURL: string = getEnv("REACT_APP_SAERPTRANS_API_HOST");

export const getCpfCnpjToken = () => store.getState().login.parceiro?.cpf_cnpj || '';

export const apiCall = (config: AxiosRequestConfig) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const request = axios({
    ...config,
    baseURL: APIURL,
    cancelToken: source.token,
    headers: {
      "cpf-cnpj": `${getCpfCnpjToken()}`,
    },
  });

  (request as any)[CANCEL] = () => source.cancel();
  return request;
}

export const saerpTransApiCall = (config: AxiosRequestConfig) =>
  axios({
    ...config,
    baseURL: SAERPTRANSAPIURL,
  });
