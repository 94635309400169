import { FormControl, FormErrorMessage, RadioGroup, RadioGroupProps } from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";

type RadioFieldProps = RadioGroupProps & {
  name: string;
};

const RadioField: React.FC<RadioFieldProps> = ({
  size: _,
  children,
  ...props
}) => {
  const [field, { error, touched }, { setValue }] = useField(props.name);
  return (
    <FormControl isInvalid={touched && error ? true : false}>
      <RadioGroup
        {...field}
        {...props}
        onChange={(val) => {
          setValue(parseInt(val));
        }}
        id={field.name}
        isInvalid={touched && error ? true : false}
      >
        {children}
      </RadioGroup>
      {error && <FormErrorMessage ml={4} mt={-2}>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default RadioField;
