import { Flex, Button } from "@chakra-ui/react"
import InputImage from "../../../components/InputImage";
import { useEffect, useState } from "react";
import ImageCameraButton from "./ImageCameraButton";
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";

const Images = ({ large = false }) => {
  const [cameraOnline, setCameraOnline] = useState(false);

  useEffect(() => {
    if (!navigator.mediaDevices) {
      setCameraOnline(false);
      console.log('Images -> setCameraOnline false');
    } else {
      console.log('Images -> navigator.mediaDevices');
      // navigator.mediaDevices
      //   .getUserMedia({ video: true })
      //   .then(function (stream) {
      //     console.log('Images -> navigator.mediaDevices true');
      //     setCameraOnline(true);
      //     stream.getTracks().forEach((track) => track.stop());
      //   })
      //   .catch(function (error) {
      //     console.log('Images -> navigator.mediaDevices false', error);
      //     setCameraOnline(false);
      //   });
    }
  }, [])

  const isMoblie = useSelector((state: RootState) => state.login.isMobile);

  return (
    <>
      <Flex pt={4} width="full" justifyContent="center" wrap="wrap">
        {cameraOnline && isMoblie ? <ImageCameraButton name="url_imagem1" label="Adicionar Foto do Canhoto" mb={2} /> : <InputImage large={large} mb="2" label="Adicionar Foto do Canhoto" name="url_imagem1" />}
      </Flex>
      <Flex pb={4} width="full" justifyContent="center" wrap="wrap">
        {cameraOnline && isMoblie ? <ImageCameraButton name="url_imagem2" label="Adicionar Foto 2" /> : <InputImage large={large} label="Adicionar Foto 2" name="url_imagem2" />}
      </Flex>
    </>
  )
}

export default Images;