import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginActions } from "../modules/login/reducer";

function useIsMobile() {
  const dispatch = useDispatch();
  const largura = window.innerWidth;

  useEffect(() => {
    function handleResize() {
      dispatch(loginActions.setaIsMobile(largura <= 768))
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch, largura]);
}

export default useIsMobile;