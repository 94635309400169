import { useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../modules/app/mainReducer";
import { entregaActions } from "../modules/entrega/reducer";
import { useEffect } from 'react';
import { transbordoActions } from "../modules/transbordo/reducer";

export function useSuccess() {
  const success = useSelector((state: RootState) => state.entrega.success || state.transbordo.success);
  const toast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      toast({
        title: 'Sucesso!',
        description: success,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      dispatch(entregaActions.setSuccess(''));
      dispatch(transbordoActions.transbordoSuccess(''));
    }
  }, [dispatch, success]);
}
