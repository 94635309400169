import { Button, Center, Flex, Heading, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"
import { ButtonBack, Layout } from "../../../components"
import { RootState } from "../../app/mainReducer";
import { Temporizador } from "../components";
import { entregaActions } from "../reducer";
import { useState } from 'react';
import { FiArrowDownLeft, FiCheck, FiCornerRightDown, FiMapPin, FiX } from "react-icons/fi";
import { CargaItem } from "../../carga/types";

const OpcoesEntrega = () => {
  const { tipo, ukey: j14_ukey, j10_ukey } = useParams();
  const e_transbordo = tipo === 'transbordo';
  const carga = useSelector((state: RootState) =>
    e_transbordo ? state.carga.cargas?.find((i) => i.j14_ukey === j14_ukey) : // transbordo busca apenas lá na listagem
      (state.carga.carga || state.carga.cargas?.find((i) => i.j14_ukey === j14_ukey))
  );
  const nota = useSelector((state: RootState) => carga?.itens?.find((i: CargaItem) => i.j10_ukey === j10_ukey));

  const e_reentrega = e_transbordo ? carga?.e_reentrega == '1' : nota?.e_reentrega == '1';

  const [passo, setPasso] = useState(1);
  const dispatch = useDispatch();
  const registraChegadaNoCliente = () => {
    dispatch(entregaActions.registraChegadaNoCliente({
      e_transbordo,
      j14_ukey: j14_ukey as string,
      j10_ukey: j10_ukey as string,
    }));

    setPasso(2);
  }
  const navigate = useNavigate();

  const prosseguir = (to: string) => {
    switch (to) {
      case 'entrega':
        if (e_transbordo) {
          navigate(`/entrega/transbordo/${j14_ukey}/opcoes/entregar`)
          return;
        }

        navigate(`/entrega/nota/${j14_ukey}/${j10_ukey}/opcoes/entregar`)
        break;
      case 'devolucao':
        if (e_transbordo) {
          navigate(`/entrega/transbordo/${j14_ukey}/opcoes/devolucao`)
          return;
        }

        navigate(`/entrega/nota/${j14_ukey}/${j10_ukey}/opcoes/devolucao`)
        break;
      case 'ausente':
        if (e_transbordo) {
          navigate(`/entrega/transbordo/${j14_ukey}/opcoes/cliente-ausente`)
          return;
        }

        navigate(`/entrega/nota/${j14_ukey}/${j10_ukey}/opcoes/cliente-ausente`)
        break;

      default:
        break;
    }
  }

  return (
    <Layout showFooter={true} isLoading={false} title={
      <>
        <ButtonBack />
        {passo > 1 && <Temporizador />}
      </>
    }>
      <Center mt={8}>
        {e_transbordo && <Heading>Finalizar Transbordo {carga?.numero}</Heading>}
        {!e_transbordo && <Heading>Finalizar Nota {nota?.nota_numero}</Heading>}
      </Center>
      <Center mx={4} mb={8} mt={2}>
        {e_transbordo && <Heading size="sm">{carga?.transbordo_nome}</Heading>}
        {!e_transbordo && <Heading size="sm">{nota?.cliente_razao_social}</Heading>}
      </Center>

      <Flex width="full" wrap="wrap" >
        {passo === 1 &&
          <Button size="xl" onClick={registraChegadaNoCliente} mx={2} colorScheme="blue" padding={6} width="full">
            <Text fontSize="18">REGISTRAR CHEGADA NO {e_transbordo ? 'TRANSBORDO' : 'CLIENTE'}</Text>
            <Flex ml={2} >
              <FiCornerRightDown color="white" fontSize="1.5rem" />
            </Flex>
          </Button>
        }
        {passo === 2 &&
          <Flex width="full" direction="column" mx={2}>
            <Button size="xl" mb={4} colorScheme="green" padding={6} width="full" onClick={() => prosseguir('entrega')}>
              <Text fontSize="20" mr={2}>REGISTRAR {e_reentrega ? 'REENTREGA' : 'ENTREGA'}</Text>
              <Flex position="absolute" right={3} padding={1} borderRadius={25} bg="white">
                <FiCheck color="green" fontSize="1.8rem" />
              </Flex>
            </Button>
            <Button size="xl" mb={4} colorScheme="yellow" padding={6} width="full" onClick={() => prosseguir('devolucao')}>
              <Text fontSize="20" mr={2}>
              CLIENTE RECUSOU A ENTREGA <br /> <small>DEVOLUÇÃO TOTAL DA NOTA</small>
              </Text>
              <Flex position="absolute" right={3} padding={1} borderRadius={25} bg="white">
                <FiArrowDownLeft color="yellow" fontSize="1.8rem" />
              </Flex>

            </Button>
          {/*<Button size="xl" mb={4} colorScheme="red" padding={6} width="full" onClick={() => prosseguir('ausente')}>
              <Text fontSize="20" mr={2}>{e_transbordo ? 'TRANSBORDO' : 'CLIENTE'} AUSENTE</Text>
              <Flex position="absolute" right={3} padding={1} borderRadius={25} bg="white">
                <FiX color="red" fontSize="1.8rem" />
              </Flex>
            </Button>
          */}
          </Flex>
        }
      </Flex>
    </Layout>
  )
}

export default OpcoesEntrega;