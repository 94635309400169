import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { getDateTime } from "../../utils/getDateTime";
import { novoGestaoDeEntrega } from "./data";
import { GestaoEntrega, LocalizacaoMotorista } from "./types";
import { differenceInMinutes } from "date-fns";
import { Carga } from "../carga/types";

type IGeolocalizacao = {
  latitude: number;
  longitude: number;
}

type TInitialState = {
  error?: string;
  success?: string;
  isLoading?: boolean;
  gestao_entrega_temporario?: GestaoEntrega | null;
  geolocalizacao?: IGeolocalizacao;
  gestao_entrega_pendentes: GestaoEntrega[];
  localizacoes_pendentes: LocalizacaoMotorista[];
};

const initialState: TInitialState = {
  gestao_entrega_pendentes: [],
  localizacoes_pendentes: [],
};

interface IRegistraChegadaNoCliebte {
  j14_ukey: string;
  j10_ukey: string;
  e_transbordo: boolean;
}

const reducer = createSlice({
  name: "entrega",
  initialState,
  reducers: {
    setError(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.success = "";
    },
    setSuccess(state, { payload }: PayloadAction<string>) {
      state.success = payload;
      state.error = "";
    },
    registraChegadaNoCliente(state, { payload }: PayloadAction<IRegistraChegadaNoCliebte>) {
      const novoGestao = novoGestaoDeEntrega();
      novoGestao.j81_ukeyp = payload.e_transbordo ? payload.j14_ukey : payload.j10_ukey;
      novoGestao.j81_par = payload.e_transbordo ? 'J14' : 'J10';
      novoGestao.data_inicio = getDateTime();

      if (state.gestao_entrega_temporario?.data_inicio && !state.gestao_entrega_temporario?.data_fim) {
        const diferencaEntreMinutos = differenceInMinutes(new Date(), new Date(state.gestao_entrega_temporario.data_inicio))

        if (diferencaEntreMinutos < 30) {
          novoGestao.data_inicio = state.gestao_entrega_temporario.data_inicio;
        }
      }

      state.gestao_entrega_temporario = novoGestao;
    },
    registraGeolocalizacao(state, { payload }: PayloadAction<IGeolocalizacao>) {
      state.geolocalizacao = payload;
    },
    registraNovoGestaoEntrega(state, { payload }: PayloadAction<{ gestao: GestaoEntrega; carga: Carga }>) {
      state.error = "";

      const latitude = state.geolocalizacao?.latitude as number;
      const longitude = state.geolocalizacao?.longitude as number;

      if (payload?.carga?.e_retira != '1') {
        if (!latitude || !longitude) {
          state.error = "Ative a geolocalização do navegador para continuar!";
          return;
        }
      }

      if (state.gestao_entrega_pendentes.find(gestao => gestao.j81_ukeyp === payload.gestao.j81_ukeyp && gestao.e_tentativa_entrega == 0)) return;

      state.gestao_entrega_pendentes.push({
        ...payload.gestao,
        data_entrega: getDateTime(),
        latitude,
        longitude,
        data_fim: getDateTime(),
      });

      state.gestao_entrega_temporario = null;
    },
    requestEnviaGestaoEntrega(state, _: PayloadAction<GestaoEntrega>) {
      state.error = "";
      state.success = "";
    },
    retiraGestaoPendente(state, { payload }: PayloadAction<string>) {
      const gestaoPendenteIndex = state.gestao_entrega_pendentes.findIndex(e => e.j81_ukeyp == payload);

      if (gestaoPendenteIndex < 0) return;

      state.gestao_entrega_pendentes.splice(gestaoPendenteIndex, 1);
    },
    registraNovaLocalizacaoMotorista(state, { payload }: PayloadAction<string>) {
      if (!state.geolocalizacao) return;

      if (!state.localizacoes_pendentes) state.localizacoes_pendentes = [];

      state.localizacoes_pendentes.push({
        latitude: state.geolocalizacao.latitude,
        longitude: state.geolocalizacao.longitude,
        ukeyp: payload,
        par: 'j14',
        data: getDateTime(),
      });
    },
    requestEnviaLocalizacaoMotorista(state, { payload }: PayloadAction<LocalizacaoMotorista>) {
      state.error = "";
      state.success = "";

      const localizacaPendenteIndex = state.localizacoes_pendentes.findIndex(e => e.data == payload.data);

      if (localizacaPendenteIndex < 0) return;

      state.localizacoes_pendentes.splice(localizacaPendenteIndex, 1);
    },
  },
});

export const entregaActions = reducer.actions;

export default reducer.reducer;
